import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer class="py-2 pink" style={{marginTop:"30px"}}>
        <div class="container">
          <p class="float-right">
            <Link
              to={"/privacy"}
              class="text-white"
            >
              Privacy
            </Link>
            |
            <Link
              to={"/terms"}
              class="text-white"
            >
              Terms
            </Link>
            |
            <Link
              to={"/disclaimer"}
              class="text-white"
            >
              Disclaimer
            </Link>
            &nbsp;
            <a href="#" class="text-white">
              <img src="assets/top.png" />
            </a>
          </p>

          <p class="text-white">
            <em> Ⓒ 2017-2023 friendsdunia.com</em>&nbsp;&nbsp;
            <Link
              to={"/"}
              class="text-white"
            >
              Home
            </Link>
            |
            <Link
              to={"/aboutus"}
              class="text-white"
            >
              About
            </Link>
            |
            <Link
              to={"/services"}
              class="text-white"
            >
              Services
            </Link>
            |
            <Link
              to={"/contactus"}
              class="text-white"
            >
              Contact Us
            </Link>
          </p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
