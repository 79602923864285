import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./component/Home";
import Header from "./include/Header";
import Footer from "./include/Footer";
import Aboutus from "./component/Aboutus";
import Services from "./component/Services";
import Contact from "./component/Contact";
import Privacy from "./component/Privacy";
import Terms from "./component/Terms";
import Disclaimer from "./component/Disclaimer";
function App() {
  return (
    <>
    <Router>
      <Header />
      <Routes>
        <Route exect path="/" element={<Home />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
      </Routes>
      <Footer />
    </Router>
    </>
  );
}

export default App;
