import { React, useState, useEffect } from "react";
import webContent from "../include/Connection";
import axios from "axios";
import { Link } from "react-router-dom";
import Registration from "../widgets/Registration";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, EffectCube, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
function Home() {
  const [users, setUsers] = useState([]);
  const [city, setCity] = useState('');
  const [loading, setLoading] = useState(false);
  const fetchUsers = async () => {
    axios
      .post(
        webContent.webLink + "/api/users.php",
        { SUBMIT: "USERS"},
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        //alert(res.data)
        console.log(res.data);
        setLoading(false);
        setCity(res.data.city)
        setUsers(res.data.userinfo);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchUsers();
    const interval = setInterval(() => {
      fetchUsers();
    }, 5000);
    //setLoading(true);
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <div className="container-fluid bg-light py-2">
        <div className="container onlinenow">
          <h2 className="py-3" align="center">
            Meet Girls in {city} Now!!
          </h2>
          <p align="center">India's Trusted Online Friendship Website</p>
          <p align="center">
            <img src="assets/line11.png" className="img-fluid" />
          </p>

          <div className="row mb-5 imgAppend">
            {users.length > 0 &&
              users.map((userObj, index) => (
                <div className="col-md-3 col-6 ss">
                  <center>
                    <Link
                    target="_blank"
                      to={
                        "https://api.whatsapp.com/send?phone="+userObj.phone+"&text=I%20want%20to%20join%20friendship%20%E2%99%A5"
                      }
                    >
                      <img
                        src={
                          webContent.webLink +
                          "/assets/images/profile/" +
                          userObj.image
                        }
                        className="img-fluid img-thumbnail rounded"
                        alt="a1"
                      />
                      <p className="text-dark">
                        {userObj.name}
                        <small className="ani4"></small>
                      </p>
                    </Link>
                  </center>
                </div>
              ))}
          </div>
        </div>
      </div>

      <Registration />

     

      <div className="container">
        <h2 className="pt-3" align="center">
          Greetings! friendsdunia.com
        </h2>
        <p align="center">
          <img src="assets/line11.png" className="img-fluid" />
        </p>

        <div className="row">
          <div className="col-md-8">
            <p>
              Friendship and Love come together. We all need someone to share
              our feelings, our emotions where we feel relaxed and complete.
              friendsdunia.com is a platform where you meet thousands of Indian
              girls from which you can make friendships, relationships and even
              meet.
              <br />
              <br />
              No matter what your current age, this platform is open to all with
              all different age group girls waiting for you. so if you are the
              one who want to have friendship and do not want lonely anymore
              than this is your moment to explore yourself and have Girlfriend
              <br />
              <br />
              Whether you are doing a job, student, business, etc we all need a
              partner where we feel complete. So here is the one single step
              where you directly invite tons of girlfriends. you can share
              numbers and directly choose to talk to them.
              <br />
              <br />
              After you sign up you are ready to explore thousands of girls of
              your choice where you can start meeting and chatting. Also, our
              team will coordinate and understand your needs quickly and they
              will provide you with the best services.
              <br />
              <br />
              So, there is no other perfect time to start a friendship. RIGHT
              NOW is the moment where you can have a girlfriend and start
              chatting and talk and make yourself confident, smart and become a
              Macho MAN.
              <br />
              <br />
            </p>
          </div>

          <div className="col-md-4 text-center">
            <img
              src="assets/guarantee.png"
              className="img-fluid"
              alt="100% Guarantee"
            />
          </div>
        </div>
      </div>

      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-md-3 col-6 bg-danger py-4 text-white">
            <center style={{ height: "100px" }}>
              <img src="assets/protection.png" alt="Card image cap" />
            </center>
            <h5 align="center">Protection</h5>
            <p className="small" align="center">
              Your safety is provided by leading anti-scam system in the
              industry.
            </p>
          </div>

          <div className="col-md-3 col-6 bg-dark py-4 text-white">
            <center style={{ height: "100px" }}>
              <img src="assets/verification.png" alt="Card image cap" />
            </center>
            <h5 align="center">Verification</h5>
            <p className="small" align="center">
              We verify your account to make your profile secure and genuine.
            </p>
          </div>

          <div className="col-md-3 col-6 bg-primary py-4 text-white">
            <center style={{ height: "100px" }}>
              <img src="assets/attention.png" alt="Card image cap" />
            </center>
            <h5 align="center">Attention</h5>
            <p className="small" align="center">
              We will not allow fake profile. Only access genuine data. Feel
              free to Register here
            </p>
          </div>

          <div className="col-md-3 col-6 bg-success py-4 text-white">
            <center style={{ height: "100px" }}>
              <img src="assets/communication.png" alt="Card image cap" />
            </center>
            <h5 align="center">Communication</h5>
            <p className="small" align="center">
              We talk to the client with regular interval to get review and
              feedback.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid py-3">
        <div className="container">
          <h2 className="pt-3" align="center">
            Special Membership
          </h2>
          <p align="center">
            <img src="assets/line11.png" className="img-fluid" />
          </p>
          <div className="row">
            <div className="col-md-12">
              <img
                src="assets/reg-profile-1.png"
                alt="register profile"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light">
        <h2 className="pt-3" align="center">
          Testimonials
        </h2>
        <p align="center">
          <img src="assets/line11.png" className="img-fluid" />
        </p>

        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <a href="#welcome">
                <img
                  src="assets/girl.png"
                  alt="register profile"
                  className="img-fluid mt-3"
                />
              </a>
            </div>

            <div className="col-md-8">
              <Swiper className="slideshow-container"  breakpoints={{
              576: {
                width: 576,
                slidesPerView: 1,
              },
              768: {
                width: 768,
                slidesPerView: 1,
              },
            }}
            modules={[Navigation, Pagination, A11y, EffectCube, Autoplay]}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            spaceBetween={20}
            //slidesPerView={3}
            navigation={{
              nextEl: ".prev",
              prevEl: ".next",
            }}
            pagination={{ clickable: false }}
            //scrollbar={{ draggable: false }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            effect={"slide"}
            cubeEffect={{
              shadow: false,
              slideShadows: false,
              shadowOffset: 20,
              shadowScale: 0.94,
            }}>
                <SwiperSlide className="mySlides">
                  <img src="assets/test1.png" />
                  <br />
                  <q>
                    Hi, I am BPO professional and I have been a member of
                    friendsdunia.com. I must recommend this website to
                    young people who are looking for some fun and chat. I found
                    my true love through the website.
                  </q>
                  <p className="author">- Manish Kumar</p>
                </SwiperSlide>

                <SwiperSlide className="mySlides">
                  <img src="assets/test2.png" />
                  <br />
                  <q>
                    friendsdunia.com is an amazing online dating site
                    perfect for single men and women. The site is great for
                    those looking to date, chat and wanted romance in their
                    life. Highly recommend to all.
                  </q>
                  <p className="author">- Manish Babu</p>
                </SwiperSlide>

                <SwiperSlide className="mySlides">
                  <img src="assets/test3.png" />
                  <br />
                  <q>
                    I am Student, During my leisure time I used to search some
                    of the best sites from where i can make friends, a true
                    friendship which i would go for longer period and
                    Undoubtedly friendsdunia.com is one the site. I love
                    this site. Thank you.
                  </q>
                  <p className="author">- Mukesh Sharma</p>
                </SwiperSlide>

                <a className="prev" style={{zIndex:999}}>
                  ❮
                </a>
                <a className="next" style={{zIndex:999}}>
                  ❯
                </a>
              </Swiper>

              
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-3">
        <h2 align="center">Why OneLove Friendship ?</h2>
        <div className="row py-2">
          <div className="col-md-4 col-4 bg-dark">
            <center>
              <img src="assets/onelove-registration.jpg" className="img-fluid" />
            </center>
          </div>

          <div className="col-md-4 col-4 bg-primary">
            <center>
              <img src="assets/no-fake-profile.jpg" className="img-fluid" />
            </center>
          </div>

          <div className="col-md-4 col-4 bg-danger">
            <center>
              <img src="assets/govt-approved.jpg" className="img-fluid" />
            </center>
          </div>
        </div>
      </div>

      <div className="container-fluid faq" id="faq">
        <h2 className="pt-3 font-weight-bold" align="center">
          Frequently Asked Questions
        </h2>
        <p align="center">
          <img src="assets/line11.png" className="img-fluid" />
        </p>

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>
                <button
                  className="btn btn-primary"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <h5>Q. Is This Company Genuine?</h5>
                </button>
              </p>
              <div className="collapse" id="collapseExample">
                <div className="card card-body mb-2">
                  <p className="small">
                    This company was more than 4 years old. We have more than
                    10000 registered members across India. We match many
                    couples, Its mission is also to help you - our valued
                    customer - in the best manner possible. To be with you at
                    all times, to guide you, to support you and yes, to help you
                    grow as an individual.
                  </p>
                </div>
              </div>

              <p>
                <button
                  className="btn btn-primary"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseExample2"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <h5>Q. What Kinds Of Friends Profile Are Offering?</h5>
                </button>
              </p>
              <div className="collapse" id="collapseExample2">
                <div className="card card-body mb-2">
                  <p className="small">
                    You will get all type of profile according to your choice.
                    It can be single, married, divorcee etc. All you have to do
                    is tell our customer care executive ,the friends you want
                    and we will happy to search for you the contact of the
                    friends from our database every week.
                  </p>
                </div>
              </div>

              <p>
                <button
                  className="btn btn-primary"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseExample3"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <h5>
                    Q. Whose Contact No. You Will Provide Me For Friendship?
                  </h5>
                </button>
              </p>
              <div className="collapse" id="collapseExample3">
                <div className="card card-body mb-2">
                  <p className="small">
                    The people like you and me who has registered themselves in
                    our company to get friends like you. All you have to do is
                    to register with us or call us next you should remember your
                    Membership id for reference.
                  </p>
                </div>
              </div>

              <p>
                <button
                  className="btn btn-primary"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseExample4"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <h5>
                    Q. If I Take Membership Then I Get Genuine Service &amp;
                    Risk Free?
                  </h5>
                </button>
              </p>
              <div className="collapse" id="collapseExample4">
                <div className="card card-body mb-2">
                  <p className="small">
                    Yes premiumfriendship is totally risk free where you can
                    take membership which cost is very low and enjoy with your
                    friends CHAT, FRIENDSHIP, ROMANCE, CALL, and many more Funs.
                  </p>
                </div>
              </div>

              <p>
                <button
                  className="btn btn-primary"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseExample5"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <h5>Q. The No. I Get Is Genuine And Do They Talk To Me?</h5>
                </button>
              </p>
              <div className="collapse" id="collapseExample5">
                <div className="card card-body mb-2">
                  <p className="small">
                    Yes, the no. we provide is 100% genuine, when you get the
                    phone number just give your membership ID as reference and
                    start talking. They will also talk because they have
                    register themselves in there own will. More over they also
                    need friends like you. You also chat , massege with them.
                  </p>
                </div>
              </div>

              <p>
                <button
                  className="btn btn-primary"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseExample6"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <h5>Q. Can I get my Refund of Registration ?</h5>
                </button>
              </p>
              <div className="collapse" id="collapseExample6">
                <div className="card card-body mb-2">
                  <p className="small">
                    Yes, we understand your privacy carefully. However, if you
                    are not satisfy with our services, you can always ask for
                    refund. or you can call our Customer services for further
                    talk.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light mt-3">
        <div className="container">
          <h2 align="center">Contact Us:</h2>

          <div className="row py-2">
            <div className="col-md-12 col-12">
              <center>
                Add: Salt Lake Park, Goregaon East, Maharashtra - 400080
                <br />
                Phone/WhatsApp:
                <a href="#">
                  +91-9083711504
                </a>
                <br />
                Email: info@friendsdunia.com
              </center>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
