import React from "react";
import { Link } from "react-router-dom";

function Privacy() {
  return (
    <>
      <div class="container">
        <h1 class="display-4 py-3" align="center">
          Privacy & Policy
        </h1>

        <div>
          friendsdunia is focused on furnishing you with the expert and top
          notch dating administration and is consequently dedicated to securing
          your protection. We take after every one of the techniques that are
          set out in this archive, and it should be perused and understood.
          <br />
          <br />
          All the data you fill about you on your profile will be shown on your
          profile page. We are required to expel any awful dialect or words and
          expressions that don't contain any data or bode well in light of a
          legitimate concern for different individuals and with a perspective of
          giving a quality dating administration to each individual from the
          friendsdunia site. In our online enlistment shape, we request
          that you give us certain data, for example, your introduction to the
          world date and the kind of relationship you are searching for.
          Likewise, we gather address, charge card, and bank data just from the
          individuals who wish to obtain Platinum and Diamond part benefits. We
          won't show any of your own data and they will be kept entirely private
          and confidential.
          <br />
          <br />
          We won't impart your own data to any outsider for promoting purposes
          without your consent. We are required to offer data to lawful
          specialists in the event that they have the best possible approval,
          for example, a court order or court arrange. Other than a substantial
          demand from a lawful expert your data is secure on the
          friendsdunia site. We do our best to guarantee that all data held
          identifying with you is stayed up with the latest, exact and finish,
          yet we additionally depend on you to tell us if your data requires
          refreshing or erasing. We may every once in a while send you
          messages/sms from outsiders or advancements that we feel will be of
          advantage for you to the email you furnished us with. You may withdraw
          from friendsdunia messages/sms whenever by changing your profile
          settings or by following the withdraw connect at the base of each
          friendsdunia email or you can send an email to client support.
          <br />
          <br />
          You may change or erase your own data from our database by utilizing
          the accompanying strategies: Send an email to{" "}
          <Link
            href="mailto:info@friendsdunia.com?Subject=Hello%20again"
            target="_top"
          >
            info@friendsdunia.com
          </Link>
        </div>
      </div>
    </>
  );
}

export default Privacy;
