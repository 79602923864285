import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <>
      <div class="container-fluid">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <Link class="navbar-brand" to={"/"}>
                <img
                  src="assets/logo.png"
                  alt="OneLoveFriendship"
                  class="img-fluid"
                />
              </Link>
            </div>

            <div class="col-md-6">
              <h3 align="center">
                {" "}
                Call Now:{" "}
                <a
                  href="tel:+91-9083711504"
                  class="text-white text-center px-2 rounded number-highlight3 display-4"
                >
                  9083711504
                </a>{" "}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
