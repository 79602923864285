import React, { useState } from "react";
import swal from "sweetalert";
import axios from "axios";
import webContent from "../include/Connection";
function Registration() {
  const [fullname, setFullname] = useState("");
  const [gender, setGender] = useState("Male");
  const [age, setAge] = useState("21");
  const [mobile, setMobile] = useState("");
  const [city, setCity] = useState("");
  //console.log(gender)
  const handleRegistration = (e) => {
    e.preventDefault();
    if (fullname == "") {
      swal({
        title: "Fullname",
        text: "Fullname can't be blank!",
        icon: "error",
      }).then((value) => {
        document.getElementById("fullname").focus();
      });
      return false;
    } else if (mobile == "") {
      swal({
        title: "Mobile Number",
        text: "Mobile number can't be blank!",
        icon: "error",
      }).then((value) => {
        document.getElementById("mobile").focus();
      });
      return false;
    } else if (mobile.length > 10 || mobile.length < 10) {
      swal({
        title: "Mobile Number",
        text: "Mobile number should be 10 Digit",
        icon: "error",
      }).then((value) => {
        document.getElementById("mobile").focus();
      });
      return false;
    } else if (city == "") {
      swal({
        title: "Your Location",
        text: "City can't be blank!",
        icon: "error",
      }).then((value) => {
        document.getElementById("city").focus();
      });
      return false;
    } else {
      axios
        .post(
          webContent.webLink + "/api/registration.php",
          {
            SUBMIT: "REGISTRATION",
            fullname: fullname,
            gender: gender,
            age: age,
            mobile: mobile,
            city: city,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          //alert(res.data)
          console.log(res.data.status);
          if (res.data.status == "success") {
            swal({
              title: "Registration Done",
              text: "Registration Complited Successfully",
              icon: "success",
            }).then((value) => {
              document.getElementById("city").focus();
            });
          } else {
            swal({
              title: "Mobile Number",
              text: "This mobile Number Alreadsy Exists",
              icon: "error",
            }).then((value) => {
              document.getElementById("city").focus();
            });
            return false;
          }
        })
        .catch((e) => {
          console.log(`register error ${e}`);
          //setIsLoading(false);
        });
    }
  };
  return (
    <>
      <div className="container-fluid reg-bg py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">&nbsp;</div>
            <div className="col-md-5">
              <h2 className="mt-3 text-white text-center" align="center">
                Join Now
              </h2>
              <div className="registration_form_s pb-4">
                <div
                  className="alert alert-danger"
                  id="errorMessage"
                  style={{ display: "none" }}
                >
                  <strong>Mobile no. is already exist!</strong>
                </div>
                <div
                  className="alert alert-success"
                  id="successMessage"
                  style={{ display: "none" }}
                >
                  <strong>Record has been inserted successfully!</strong>
                </div>
                <div className="ajax-loader">
                  <img
                    src="assets/imageloader.gif"
                    className="img-responsive"
                  />
                </div>
                <form
                  className="form-horizontal"
                  id="formPost"
                  method="POST"
                  onSubmit={handleRegistration}
                >
                  <div className="row">
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        id="fullname"
                        name="fullname"
                        value={fullname}
                        onChange={(e) => setFullname(e.target.value)}
                        placeholder="Name"
                      />
                      <span
                        className="red"
                        id="profile_name_error"
                        style={{ display: "none" }}
                      ></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <select
                        className="form-control"
                        id="gender"
                        name="gender"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value="Male" selected="selected">
                          Male
                        </option>
                        <option value="Female">Female</option>
                      </select>
                      <span
                        className="red"
                        id="profile_gender_error"
                        style={{ display: "none" }}
                      ></span>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <select
                        className="form-control"
                        id="age"
                        name="age"
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                      >
                        <option value="21" selected="selected">
                          21
                        </option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                        <option value="32">32</option>
                        <option value="33">33</option>
                        <option value="34">34</option>
                        <option value="35">35</option>
                        <option value="36">36</option>
                        <option value="37">37</option>
                        <option value="38">38</option>
                        <option value="39">39</option>
                        <option value="40">40</option>
                        <option value="41">41</option>
                        <option value="42">42</option>
                        <option value="43">43</option>
                        <option value="44">44</option>
                        <option value="45">45</option>
                        <option value="46">46</option>
                        <option value="47">47</option>
                        <option value="48">48</option>
                        <option value="49">49</option>
                        <option value="50">50</option>
                        <option value="51">51</option>
                        <option value="52">52</option>
                        <option value="53">53</option>
                        <option value="54">54</option>
                        <option value="55">55</option>
                        <option value="56">56</option>
                        <option value="57">57</option>
                        <option value="58">58</option>
                        <option value="59">59</option>
                        <option value="60">60</option>
                        <option value="61">61</option>
                        <option value="62">62</option>
                        <option value="63">63</option>
                        <option value="64">64</option>
                        <option value="65">65</option>
                        <option value="66">66</option>
                        <option value="67">67</option>
                        <option value="68">68</option>
                        <option value="69">69</option>
                        <option value="70">70</option>
                        <option value="71">71</option>
                        <option value="72">72</option>
                        <option value="73">73</option>
                        <option value="74">74</option>
                        <option value="75">75</option>
                        <option value="76">76</option>
                        <option value="77">77</option>
                        <option value="78">78</option>
                        <option value="79">79</option>
                        <option value="80">80</option>
                      </select>
                      <span
                        className="red"
                        id="profile_age_error"
                        style={{ display: "none" }}
                      ></span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <input
                        className="form-control"
                        type="number"
                        id="mobile"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        name="mobile"
                        placeholder="Mobile (10 Digits)"
                        maxlength="10"
                      />
                      <span
                        className="red"
                        id="profile_mobile_error"
                        style={{ display: "none" }}
                      ></span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <input
                        className="form-control"
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        id="city"
                        name="city"
                        placeholder="City"
                      />
                      <span
                        className="red"
                        id="profile_city_error"
                        style={{ display: "none" }}
                      ></span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <input
                        type="submit"
                        className="btn btn-big ani2"
                        id="formPostClick"
                        name="submit"
                        value="Friendship Now"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-sm-12 text-center rounded">
                      OR
                      <br />
                      <img src="assets/arrow-d1.png" />
                      <br />
                      <a
                        href="tel:+91-9083711504"
                        className="text-white text-center px-2 rounded number-highlight3 display-4"
                      >
                        9083711504
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Registration;
