import React from "react";

function Contact() {
  return (
    <>
      <section class="address_details">
        <div class="container">
          <div class="row">
            <div class="col-sm-4">
              <div class="address_item">
                <img src="img/soul-icon/address-1.png" alt="" />

                <h3>Address</h3>

                <p>
                  Chinar Park Chekpost,
                  <br />
                  Near Valbet Hotel Kolkata - 700013
                </p>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="address_item">
                <img src="img/soul-icon/address-2.png" alt="" />
                <h3>Phone</h3>
                <p>+91 9083711504</p>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="address_item">
                <img src="img/soul-icon/address-3.png" alt="" />
                <h3>Email</h3>
                <p>
                  info@friendsdunia.com, <br />
                  enquiry@friendsdunia.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="contact_form_area">
        <div class="container">
          <div class="welcome_title">
            <h3>Get in Touch With us</h3>

            <div class="row">
              <div class="col-md-7">
                <form
                  action="#"
                  method="post"
                  id="contactForm"
                  class="form_inner"
                  novalidate="novalidate"
                >
                  <div class="form-group col-md-12">
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      id="name"
                      placeholder="Name"
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      id="email"
                      placeholder="Email"
                    />
                  </div>

                  <div class="form-group col-md-12">
                    <input
                      type="text"
                      class="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <textarea
                      id="comment"
                      placeholder="Message"
                      name="message"
                      rows="1"
                    ></textarea>
                  </div>
                  <div class="form-group col-md-12">
                    <button
                      type="submit"
                      value="LogIn"
                      class="btn pink2 py-4 form-control login_btn"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>

              <div id="success">
                <div class="alert alert-success">
                  <strong>
                    Thank you for taking interest in our services. Our
                    representative will contact you soon.
                  </strong>
                </div>
              </div>
              <div id="error">
                <div class="alert alert-danger">
                  <strong>Email is already exist!</strong>
                </div>
              </div>

              <div class="col-md-5">
                <img src="assets/contact.png" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
