import React from "react";

function Disclaimer() {
  return (
    <>
      <div class="container">
        <h1 class="display-4 py-3" align="center">
          Disclaimer
        </h1>
        <p>
          friendsdunia including its officers, executives, specialists,
          backups and workers isn't in charge of any mistaken or erroneous
          substance posted on the friendsdunia webpage or regarding the
          friendsdunia, regardless of whether caused by clients,
          individuals or by any of the hardware or programming related with or
          used in the administration, nor for the lead of any client and
          additionally individual from the friendsdunia whether on the web
          or disconnected.
          <br />
          <br />
          friendsdunia accepts no accountability for any blunder,
          exclusion, intrusion, erasure, deformity, delay in activity or
          transmission, interchanges line disappointment, burglary or
          pulverization or unapproved access to, or change of, client as well as
          part correspondences.
          <br />
          <br />
          friendsdunia isn't in charge of any issues or specialized glitch
          of any phone system or lines, PC on-line-frameworks, servers or
          suppliers, PC gear, programming, disappointment of email or players by
          virtue of specialized issues or movement clog on the Internet or at
          any site or blend thereof, including damage or harm to clients as well
          as individuals or to some other individual's PC identified with or
          coming about because of taking part or downloading materials regarding
          the friendsdunia webpage as well as regarding the
          friendsdunia.
          <br />
          <br />
          By no means friendsdunia is in charge of any misfortune or harm
          coming about because of anybody's utilization of the Site or the
          administration as well as any substance posted on the
          friendsdunia site or transmitted to friendsdunia Members.
          The Site and the administration are given "AS-Seems to be" and
          friendsdunia explicitly disavows any guarantee of wellness for a
          specific reason or non-encroachment.
          <br />
          <br />
          friendsdunia can't ensure and does not guarantee a particular
          outcomes from utilization of the site and additionally the
          friendsdunia.
        </p>
      </div>
    </>
  );
}

export default Disclaimer;
