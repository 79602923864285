import React from "react";

function Terms() {
  return (
    <>
      <div class="container">
        <h1 class="display-4 py-3" align="center">
          Terms & Condition
        </h1>
        <p align="left">
          Welcome to friendsdunia outstanding amongst other free dating and
          long range interpersonal communication network. You should read and
          consent to these terms and conditions before you may access or view
          our Site. If it's not too much trouble read them painstakingly. By
          going by the friendsdunia Site, you are consenting to its terms.
          This Agreement is liable to change whenever, and changes are powerful
          after posting. If it's not too much trouble take note of that your
          proceeded with utilization of friendsdunia compliant with such
          change will constitute considered acknowledgment of such changes.
          <br />
          <br />
          <br />
          <strong>Eligibility :</strong> You should be eighteen or over to
          enroll as an individual from or utilize this Site. By utilizing and
          additionally seeing this site, you speak to and warrant that you have
          the right, specialist, and ability to go into this Agreement and to
          submit to the majority of its terms and conditions and that you are no
          less than 18-years old.
          <br />
          <br />
          <strong>Fees/Termination :</strong> This Agreement with you will stay
          in full power and impact while you utilize the friendsdunia
          administrations or potentially you are an individual from
          friendsdunia. You can end your participation with
          friendsdunia whenever, for any reason by educating in writing to
          end your enrollment. In the occasion you end your enrollment, in any
          circumstances, you won't be qualified for a discount of any unutilized
          membership fees.
          <br />
          <br />
          <strong>Confidentiality :</strong> It is concurred that all individual
          data given to friendsdunia will be kept classified by
          friendsdunia with the accompanying special cases: (1) fundamental
          data (i.e. age, sex) will be posted with Your part ID for others to
          see; (2)You effectively posted your own data for others to see;(3)You
          make an impression on another customer, in which you choose to uncover
          your email deliver to that customer; and additionally (4) You have
          hassled someone else through any administration given by
          friendsdunia, in which case the classification statement
          contained in this Agreement is rendered invalid and void.
          <br />
          <br />
          <strong>Using the administration :</strong> This site is intended for
          the individual utilization of individual individuals and watchers just
          and may not be utilized as a part of association with any business
          undertakings or points. Organizations may not move toward becoming
          individuals from friendsdunia and will not utilize the
          administration or site for any reason. You are exclusively in charge
          of the substance that you distribute or show on the administration, or
          transmit to different individuals, and by presenting content on any
          open zone of the administration, you consequently concede, and you
          ensure that you have the privilege to give. Companions Partner does
          not check the precision or truth of any data distributed by its
          individuals. While we maintain whatever authority is needed to erase
          or evacuate content esteemed hostile by friendsdunia members.
          <br />
          <br />
          <strong>
            You may not post copyrighted material on the site.
          </strong>{" "}
          This incorporates however isn't constrained to photos, pictures and
          content. In the event that you believe that copyrighted material has
          been posted on the site you have to contact friendsdunia with
          full points of interest of the assumed copyright infringement.
          <br />
          <br />
          <strong>Disputes between individuals : </strong>You are exclusively in
          charge of your cooperations with different friendsdunia
          individuals. Companions Partner holds without a doubt the right, yet
          has no commitment, to screen debate amongst you and different
          individuals. Your utilization of this administration is completely at
          your own particular hazard. You ought to assess what you see when
          engaged with web correspondence with individuals who are obscure to
          you, (for example, when you are utilizing message benefits) and know
          that individuals may not really be who they say they are and that
          individuals may give data or carry on in a way that is temperamental,
          misdirecting or illegal.
          <br />
          <br />
          <strong>Refund Policy :</strong> Unless there is a specialized blunder
          in the activity of the site, we have a no-discount approach after the
          administrations have been utilized. In the event that you feel that
          our administrations have been inadequate in any capacity, it would be
          ideal if you get in touch with us with your purposes behind
          guaranteeing a discount giving your client enlistment points of
          interest and Payment Transaction ID Number. Companions Partner is
          focused on giving you the expert and amazing dating administration and
          is along these lines focused on ensuring your security. We take after
          every one of the systems that are set out in this record, and it
          should be perused and understood.
          <br />
          <br />
          All the data you fill about you on your profile will be shown on your
          profile page. We are required to expel any terrible dialect or words
          and expressions that don't contain any data or bode well in light of a
          legitimate concern for different individuals and with a perspective of
          giving a quality dating administration to each individual from the
          friendsdunia site. In our online enrollment shape, we request
          that you give us certain data, for example, your introduction to the
          world date and the kind of relationship you are searching for. Also,
          we gather address, charge card, and bank data just from the
          individuals who wish to get Platinum and Diamond part benefits. We
          won't show any of your own data and they will be kept entirely private
          and confidential.
          <br />
          <br />
          We won't impart your own data to any outsider for advertising purposes
          without your authorization. We are required to offer data to lawful
          experts in the event that they have the best possible approval, for
          example, a court order or court arrange. Other than a substantial
          demand from a legitimate expert your data is secure on the
          friendsdunia site. We do our best to guarantee that all data held
          identifying with you is stayed up with the latest, exact and finish,
          yet we likewise depend on you to advise us if your data requires
          refreshing or deleting.
          <br />
          <br />
          We may now and again send you messages/sms from outsiders or
          advancements that we feel will be of advantage for you to the email
          you gave us. You may withdraw from friendsdunia messages/sms
          whenever by changing your profile settings or by following the
          withdraw interface at the base of each companion Partner email or you
          can send an email to client support.
          <br />
          <br />
          You may alter or erase your own data from our database by utilizing
          the accompanying techniques: Send an email to{" "}
          <a
            href="mailto:info@friendsdunia.com?Subject=Hello%20again"
            target="_top"
          >
            info@friendsdunia.com
          </a>
        </p>
      </div>
    </>
  );
}

export default Terms;
