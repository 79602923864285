import React from "react";

function Services() {
  return (
    <>
      <div class="container">
        <h1 class="display-4 py-3" align="center">
          Services
        </h1>

        <section class="who_we_are_area">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <img
                  src="assets/services.jpg"
                  alt="friendsdunia"
                  class="img-fluid"
                />

                <p class="py-4">
                  friendsdunia.com a web based dating website offers an
                  extensive variety of administrations to young fellows and
                  ladies to locate their genuine romance and further build up an
                  incredible relationship. The site through its administrations
                  particularly takes into account the young fellows and ladies
                  hoping to locate the ideal match. Profit our administrations
                  and appreciate an exceptional minute, visit and date to a man
                  of your decision.
                  <br />
                  <br />
                  <strong>Make Account For Free and Find Your Love:</strong> At
                  friendsdunia.com we esteem our clients and that is the
                  reason we give an opportunity to energetic people to make a
                  record on the site totally free.
                  <br />
                  <br />
                  <strong>Largest amount of Security and Privacy:</strong> We
                  regard every one of our clients and that is the reason we
                  don't share their any data to the outsider. Additionally, our
                  high security empowers us to ensure the information and give
                  the most elevated amount of security and protection.
                  <br />
                  <br />
                  <strong>Devoted Customer Care:</strong> Our 24X7 committed
                  client mind benefit help you in finding your genuine romance.
                  We have procured proficient that will make it super simple to
                  locate the intimate romance with whom you can appreciate talk,
                  and go on a sentimental date.
                  <br />
                  <br />
                  <strong>Continuous Satisfaction:</strong> Feel exceptional and
                  appreciate each sentimental minute. We give you best minute.
                  You can appreciate as much as you need with your darling
                  accomplice as much as you need.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="container">
        <h2 align="center" class="py-4 pink4 mb-2 text-white">
          Our Membership Plan
        </h2>

        <div class="row text-center">
          <div class="col-md-3">
            <div class="bmT-card bmT-pdng-t0 pink2 text-white p-3">
              <div class="bmT-member-title bmT-primary-bg-color">
                <h3>Silver</h3>
                <h4>Rs. 2,500.00 + GST</h4>
                <p>1 Months</p>
              </div>
              <div class="bmT-sub-title">
                <ul class="bmT-plan-ul text-left">
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    INSTANT ACTIVATION
                  </li>
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    PHONE OF FRIENDS
                  </li>
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    SHARED NUMBER 15
                  </li>
                  <li>
                    <i
                      class="fa fa-times bmT-text-red-color"
                      aria-hidden="true"
                    ></i>{" "}
                    DATING
                  </li>
                  <li>
                    <i
                      class="fa fa-times bmT-text-red-color"
                      aria-hidden="true"
                    ></i>{" "}
                    OFFLINE CHAT
                  </li>
                  <li>
                    <i
                      class="fa fa-times bmT-text-red-color"
                      aria-hidden="true"
                    ></i>{" "}
                    WHATSAPP NO.
                  </li>
                  <li>
                    <i
                      class="fa fa-times bmT-text-red-color"
                      aria-hidden="true"
                    ></i>{" "}
                    ONLINE CHAT
                  </li>
                  <li>
                    <i
                      class="fa fa-times bmT-text-red-color"
                      aria-hidden="true"
                    ></i>{" "}
                    VIDEO CHAT
                  </li>
                </ul>
                <a
                  href="http://www.friendsdunia.com/"
                  class="btn bmT-rsvp-submit  btn-primary"
                >
                  Sign-up Now
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="bmT-card bmT-pdng-t0 pink2 text-white p-3">
              <div class="bmT-member-title bmT-primary-bg-color">
                <h3>Gold</h3>
                <h4>Rs. 5,500.00 + GST</h4>
                <p>3 Months</p>
              </div>
              <div class="bmT-sub-title">
                <ul class="bmT-plan-ul text-left">
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    INSTANT ACTIVATION
                  </li>
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    PHONE OF FRIENDS
                  </li>
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    SHARED NUMBER 15
                  </li>
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    DATING
                  </li>
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    OFFLINE CHAT
                  </li>
                  <li>
                    <i
                      class="fa fa-times bmT-text-red-color"
                      aria-hidden="true"
                    ></i>{" "}
                    WHATSAPP NO.
                  </li>
                  <li>
                    <i
                      class="fa fa-times bmT-text-red-color"
                      aria-hidden="true"
                    ></i>{" "}
                    ONLINE CHAT
                  </li>
                  <li>
                    <i
                      class="fa fa-times bmT-text-red-color"
                      aria-hidden="true"
                    ></i>{" "}
                    VIDEO CHAT
                  </li>
                </ul>
                <a
                  href="http://www.friendsdunia.com/"
                  class="btn bmT-rsvp-submit  btn-primary"
                >
                  Sign-up Now
                </a>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="bmT-card bmT-pdng-t0  pink2 text-white p-3">
              <div class="bmT-member-title bmT-primary-bg-color">
                <h3>Platinum</h3>
                <h4>Rs. 10,500.00 + GST</h4>
                <p>12 Months</p>
              </div>
              <div class="bmT-sub-title">
                <ul class="bmT-plan-ul text-left">
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    INSTANT ACTIVATION
                  </li>
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    PHONE OF FRIENDS
                  </li>
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    SHARED NUMBER 15
                  </li>
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    DATING
                  </li>
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    OFFLINE CHAT
                  </li>
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    WHATSAPP NO.
                  </li>
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    ONLINE CHAT
                  </li>
                  <li>
                    <i
                      class="fa fa-times bmT-text-red-color"
                      aria-hidden="true"
                    ></i>{" "}
                    VIDEO CHAT
                  </li>
                </ul>
                <a
                  href="http://www.friendsdunia.com/"
                  class="btn bmT-rsvp-submit btn-primary"
                >
                  Sign-up Now
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="bmT-card bmT-pdng-t0 pink2 text-white p-3">
              <div class="bmT-member-title bmT-primary-bg-color">
                <h3>VIP</h3>
                <h4>Rs. 15,000.00 + GST</h4>
                <p>18 Months</p>
              </div>
              <div class="bmT-sub-title">
                <ul class="bmT-plan-ul text-left">
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    INSTANT ACTIVATION
                  </li>
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    PHONE OF FRIENDS
                  </li>
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    SHARED NUMBER 15
                  </li>
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    DATING
                  </li>
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    OFFLINE CHAT
                  </li>
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    WHATSAPP NO.
                  </li>
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    ONLINE CHAT
                  </li>
                  <li>
                    <i
                      class="fa fa-check bmT-text-primary-color"
                      aria-hidden="true"
                    ></i>{" "}
                    VIDEO CHAT
                  </li>
                </ul>
                <a
                  href="http://www.friendsdunia.com/"
                  class="btn bmT-rsvp-submit btn-primary"
                >
                  Sign-up Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
