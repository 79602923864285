import React from "react";

function Aboutus() {
  return (
    <>
      <div class="container py-5">
        <div class="row">
          <div class="col-md-7">
            <div class="welcome_title">
              <h3>About friendsdunia</h3>
            </div>

            <p>
              friendsdunia.com a standout amongst the most exceptional web
              based dating destinations causes you in finding your genuine
              romance. Meet a man with whom you need to visit, talk, date and
              bloom an agreeable relationship. In today's cutting edge way of
              life no one needs to be single whether it is a kid or a young
              lady. To take care of the issue friendsdunia.com has several
              true profile of young ladies and men searching for a companion and
              date.
              <br />
              <br />
              With such a significant number of web based dating locales
              friendsdunia.com remain in front of everybody. The site
              especially centers around coordinating you the best and reasonable
              accomplice, with whom you can spend a continuous well disposed
              minute without fail. In this site, you can visit and express your
              inclination to your accomplice openly with no dithering.
              <br />
              <br />
              With such a significant number of new and astounding highlights
              the site will keep you locked in. You can peruse the profile of
              several ladies and men. You would yourself be able to can without
              much of a stretch access and pick the accomplice. Turn into an
              individual from friendsdunia.com and appreciate a fun life
              more than ever.
            </p>
            <br />
          </div>
          <div class="col-md-5">
            <img
              src="assets/couple.png"
              class="img-responsive"
              style={{position:"relative",top:"69px"}}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Aboutus;
